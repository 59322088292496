import * as React from 'react';
import {
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Button,
	Typography,
	Box,
	Drawer,
} from '@mui/material';
import Carousel from 'react-material-ui-carousel';

export default function CardComponent({ cardData }) {
	const [autoPlay, setAutoPlay] = React.useState(false);
	const [anchor, setAnchor] = React.useState(false);
	const [index, setIndex] = React.useState(0);

	function handleMouseEnter() {
		setAutoPlay(true);

		setTimeout(()=> {
			setIndex(1);
		},900)
	}
	function handleMouseLeave() {
		setAutoPlay(false);

		setTimeout(()=> {
			setIndex(0);
		},1000)
	}

	const toggleDrawer = (anchor, open) => (event) => {
		if (
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return;
		}

		setAnchor(open);
	};

	return (
		<>
			<Card
				sx={{
					maxWidth: '100%',
					height: '100%',
					boxShadow: '0 0 6px 0 #3336',
				}}
			>
				<Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
					<Carousel
						indicators={false}
						stopAutoPlayOnHover={false}
						autoPlay={autoPlay}
						interval={1000}
						index={index}
						navButtonsAlwaysInvisible
					>
						{cardData?.images?.map((image, i) => (
							<CardMedia
								component="img"
								alt={cardData?.name}
								height="200"
								// sx={{ objectFit: 'contain' }}
								key={i}
								image={image}
							/>
						))}
					</Carousel>
				</Box>
				<CardContent>
					<Typography gutterBottom variant="h5" component="div">
						{cardData?.name}
					</Typography>
				</CardContent>
				<CardActions>
					<Button size="small" href={cardData?.url} target="_blank">
						Visit
					</Button>
					<Button
						size="small"
						href={cardData?.manual_link}
						target="_blank"
						disabled={!cardData?.manual_link}
						// onClick={toggleDrawer('bottom', true)}
					>
						Learn More
					</Button>
				</CardActions>
			</Card>
			<DrawerComponent
				state={[anchor, setAnchor]}
				toggleDrawer={toggleDrawer}
				anchorName={'bottom'}
				content={cardData}
			/>
		</>
	);
}

function DrawerComponent({
	state: [anchor],
	toggleDrawer,
	anchorName,
	content,
}) {
	return (
		<Box onClick={toggleDrawer(anchor, false)}>
			<Drawer
				anchor={anchorName}
				open={anchor}
				onClose={toggleDrawer(anchorName, false)}
			>
				{/* {list(anchor)} */}
				<Box
					py={4}
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						width: '80dvw',
						mx: 'auto',
					}}
				>
					<Typography variant="h5" sx={{ fontWeight: 'medium' }}>
						{content?.name}
					</Typography>
					<Button variant="contained" href={content?.url}>
						Visit
					</Button>
				</Box>
				<Box sx={{ width: '80dvw', mx: 'auto', pb: 5 }}>
					<Carousel
						// indicators={false}
						stopAutoPlayOnHover={false}
						autoPlay={false}
						interval={5000}
						navButtonsAlwaysVisible
					>
						{content?.images?.map((image, i) => (
							<CardMedia
								component="img"
								alt="green iguana"
								// height="75"
								sx={{ height: '70dvh' }}
								key={i}
								image={image}
							/>
						))}
					</Carousel>
				</Box>
			</Drawer>
		</Box>
	);
}
