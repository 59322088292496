import { Box, Unstable_Grid2 as Grid, Typography } from '@mui/material';
import CardComponent from '../../components/Card';

export default function Home({ appData }) {
	return (
		<>
			<Box
				sx={{ width: { xs: 'calc(100dvw - 1em)', md: '80dvw' }, mx: 'auto' }}
			>
				<Box sx={{ mb: 6 }}>
					<Typography
						variant="h4"
						sx={{ textAlign: 'center', fontWeight: 'medium', mb: 2 }}
					>
						डिजिटल पालिका
					</Typography>
					<Typography
						variant="body1"
						sx={{ textAlign: 'center', color: 'GrayText' }}
					>
						पालिका भित्रका शाखा र वडाबाट प्रदान गरिने सिफारिस सेवा तथा पत्र,
						दर्ता-चलानी तथ्यांक विवरणको
					</Typography>
					<Typography
						variant="body1"
						sx={{ textAlign: 'center', color: 'GrayText' }}
					>
						सहजीकरणको लागि निर्मित एकिकृत प्रणाली
					</Typography>
				</Box>
				<Grid container spacing={4} sx={{ mx: 'auto', pb: 16 }}>
					{appData?.applications?.map((app) => (
						<Grid key={app.id} xs={12} sm={6} lg={4} item>
							<CardComponent cardData={app} />
						</Grid>
					))}
				</Grid>
			</Box>
		</>
	);
}
