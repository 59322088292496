import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Layout from './layouts';
import { useEffect, useState } from 'react';

function App() {
	const [loading, setLoading] = useState(true);
	const [appData, setAppData] = useState(null);

	useEffect(() => {
		try {
			setLoading(true);
			fetch('/data.json').then(async (response) => {
				let res = await response.json();
				setAppData(res);
			});
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
		setLoading(false);
	}, []);

	console.log(appData);

	return (
		<>
			<Routes>
				<Route path="/" element={<Layout loading={loading} />}>
					<Route index element={<Home appData={appData} />} />
				</Route>
			</Routes>
		</>
	);
}

export default App;
