import { Outlet } from 'react-router-dom';
import DrawerAppBar from './AppBar';
import { Box } from '@mui/material';
import LoopIcon from '@mui/icons-material/Loop';
import Footer from './Footer';

export default function Layout({ loading }) {
	if (loading)
		return (
			<Box
				sx={{
					width: '100dvw',
					height: '100dvh',
					display: 'grid',
					placeContent: 'center',
				}}
			>
				<Box>
					<LoopIcon
						sx={{ color: 'primary.main', fontSize: '5dvmax' }}
						className="spin"
					/>
				</Box>
			</Box>
		);
	return (
		<>
			<DrawerAppBar />
			<Box sx={{ minHeight: '100dvh' }}>
				<Outlet />
			</Box>
			<Footer />
		</>
	);
}
